<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <v-link class="navbar-item" href="/">
        <img :src="Logo" alt="Lazy Box" width="40" height="40" />
        <p>Lazy Box</p>
      </v-link>

      <a
        role="button"
        class="navbar-burger"
        v-bind:class="{ 'is-active': showSideBar }"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
        v-on:click="ToggleSideBar"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div
      id="navbarBasicExample"
      class="navbar-menu"
      v-bind:class="{ 'is-active': showSideBar }"
    >
      <div class="navbar-start">
        <v-link href="/" class="navbar-item"> Home </v-link>

        <!-- <a class="navbar-item"> Documentation </a>

        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> More </a>

          <div class="navbar-dropdown">
            <a class="navbar-item"> About </a>
            <a class="navbar-item"> Jobs </a>
            <a class="navbar-item"> Contact </a>
            <hr class="navbar-divider" />
            <a class="navbar-item"> Report an issue </a>
          </div> 
        </div> -->
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <v-link href="/signup" class="button is-primary">
              <strong>Sign up</strong>
            </v-link>
            <v-link href="/login" class="button is-light"> Log in </v-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Logo from "@/assets/logo.png";

import VLink from "./VLink.vue";

export default {
  name: "Nav",
  data: () => ({
    showSideBar: false,
  }),
  methods: {
    ToggleSideBar() {
      this.showSideBar = !this.showSideBar;
    },
  },
  components: {
    VLink,
  },
  setup: () => {
    return {
      Logo,
    };
  },
};
</script>