<template>
  <MainLayout>
    <div class="container pt-6">
      <div class="columns is-vcentered is-desktop">
        <div class="column">
          <div class="columns is-vcentered">
            <h1 class="title is-1 is-spaced">Lazy Box</h1>
            <img :src="LogoSrc" width="200" />
          </div>
          <h2 class="subtitle is-2">We get you the ingredients.</h2>
          <h2 class="subtitle is-2">You handle the rest.</h2>
        </div>
        <img :src="CookingSrc" width="500" />
      </div>
    </div>
  </MainLayout>
</template>

<script>
import LogoSrc from "@/assets/logo.png";
import CookingSrc from "@/assets/undraw_cooking_lyxy.svg";
import MainLayout from "../layouts/Main.vue";

export default {
  components: {
    MainLayout,
  },

  setup: () => {
    return {
      CookingSrc,
      LogoSrc,
    };
  },
};
</script>

<style>
</style>