import { createApp, h } from 'vue'
import routes from './routes'

import '@/styles/custom.scss'

const Router = {
    data: () => ({
        currentRoute: window.location.pathname
    }),

    computed: {
        CurrentComponent() {
            const matchingPage = routes[this.currentRoute] || '404'
            return require(`./pages/${matchingPage}.vue`).default
        }
    },

    render() {
        return h(this.CurrentComponent)
    },

    created() {
        window.addEventListener('popstate', () => {
            this.currentRoute = window.location.pathname
        })
    }
}

createApp(Router).mount('#app')
