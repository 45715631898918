<template>
  <MainLayout>
    <div class="columns is-centered is is-vcentered pt-6">
      <div class="card column is-5">
        <header class="card-header">
          <p class="card-header-title">Sign Up</p>
        </header>
        <div class="card-content">
          <div class="content">
            <div class="field">
              <label class="label">Name</label>
              <div class="control">
                <input class="input" type="text" placeholder="Jane Doe" />
              </div>
            </div>

            <div class="field">
              <label class="label">Username</label>
              <div class="control has-icons-right">
                <input
                  class="input"
                  type="text"
                  placeholder="jane123"
                  value=""
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
                <span class="icon is-small is-right">
                  <i class="fas fa-check"></i>
                </span>
              </div>
              <!-- <p class="help is-success">This username is available</p> -->
            </div>

            <div class="field">
              <label class="label">Email</label>
              <div class="control has-icons-right">
                <input
                  class="input"
                  type="email"
                  placeholder="jane.doe@gmail.com"
                  value=""
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i>
                </span>
                <span class="icon is-small is-right">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
              </div>
              <!-- <p class="help is-danger">This email is invalid</p> -->
            </div>

            <div class="field">
              <label class="label">Password</label>
              <div class="control has-icons-right">
                <input
                  class="input"
                  type="password"
                  placeholder="Please enter your password"
                  value=""
                />
              </div>
            </div>

            <div class="field">
              <label class="label">Password... Again</label>
              <div class="control has-icons-right">
                <input
                  class="input"
                  type="password"
                  placeholder="Please enter the same password above"
                  value=""
                />
              </div>
            </div>

            <div class="field">
              <div class="control">
                <label class="checkbox">
                  <input type="checkbox" />
                  I agree to the <a href="#">terms and conditions</a>
                </label>
              </div>
            </div>
          </div>
        </div>
        <footer class="card-footer">
          <v-link href="/" class="card-footer-item">Cancel</v-link>
          <a href="#" class="card-footer-item">Create Account</a>
        </footer>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import VLink from "../components/VLink.vue";
import MainLayout from "../layouts/Main.vue";

export default {
  components: {
    MainLayout,
    VLink,
  },
};
</script>