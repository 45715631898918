<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <strong>Lazy Box</strong> by
        <a href="https://cjadkins.com">Christopher J. Adkins</a>.
      </p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>
</style>