<template>
  <main-layout>
    <div class="columns is-centered is is-vcentered pt-6">
      <div class="card column is-5">
        <header class="card-header">
          <p class="card-header-title">Login</p>
        </header>
        <div class="card-content">
          <div class="content">
            <div class="field">
              <label class="label">Username or Email</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="jane.doe@gmail.com"
                />
              </div>
            </div>

            <div class="field">
              <label class="label">Password</label>
              <div class="control has-icons-right">
                <input
                  class="input"
                  type="password"
                  placeholder="Please enter your password"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
        <footer class="card-footer">
          <a href="#" class="card-footer-item">Forgot Password</a>
          <a href="#" class="card-footer-item">Login</a>
        </footer>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
export default {
  components: {
    MainLayout,
  },
};
</script>